import { HttpClient } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { LOCAL_STORAGE_ACTIVE_ACCOUNT_ID, LOCAL_STORAGE_PROFILE_ID, TOKEN } from '@app/core/constants/app.constant';
import { environment } from '@core/environments/environment';
import {
  ActivateAccount,
  ForgotPassword,
  ForgotPasswordResponse,
  Login,
  LoginResponse,
  CreateProfile,
  ResetPassword,
  ResetPasswordResponse,
  UpdatePassword,
  CreateProfileResponse,
  ActivateAccountResponse,
  CreateTransactionPin,
  CreateTransactionPinResponse,
} from '@core/models/client/auth';
import { jwtDecode } from 'jwt-decode';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  http = inject(HttpClient);
  BASE_URL = environment.CLIENT_BASE_URL + '/auth';
  private authTokenKey = 'accessToken';
  private returnURLKey = 'return_url';
  private router = inject(Router);

  // currentUserSignal = signal<undefined | null>(undefined);
  // currentUserTokenSignal = signal<string>('');

  createProfile(profileInfo: CreateProfile): Observable<CreateProfileResponse> {
    return this.http.post<CreateProfileResponse>(
      `${this.BASE_URL}/signup`,
      profileInfo
    );
  }
  tokenIsExpired(): boolean {
    const decodedToken = this.getDecodedAccessToken();
    const now = Math.floor(Date.now() / 1000);
    if (decodedToken && (decodedToken?.exp - now) > 0) {
      return true;
    }
    return false;
  }
  getDecodedAccessToken(): any | null {
    try {
      const str = sessionStorage.getItem(this.authTokenKey);
      return str ? jwtDecode(str) : null;
    } catch (Error) {
      return null;
    }
  }

  getToken(): string | null {
    return sessionStorage.getItem(this.authTokenKey);
  }

  storeReturnUrl() {
    const currentUrl = this.router.url;
    if (!this.hasRedirectURL()) {
      sessionStorage.setItem(this.returnURLKey, currentUrl);
    }
  }

  hasRedirectURL(): boolean {
    return sessionStorage.getItem(this.returnURLKey) ? true : false;
  }


  activateAccount(otp: ActivateAccount): Observable<ActivateAccountResponse> {

    const AUTH_TOKEN = localStorage.getItem('signupToken');

    // Merge the token into the body along with the otp
    const requestBody = {
      ...otp, // Spread the existing otp object
      token: AUTH_TOKEN // Add the token to the body
    };
    return this.http.post<ActivateAccountResponse>(

      `${this.BASE_URL}/activate-account`,
      requestBody
    );
  }

  resendOtp(): Observable<{ status: string; message: string }> {
      const AUTH_TOKEN = localStorage.getItem('signupToken');

    // Merge the token into the body along with the otp
    const requestBody = {
      
      token: AUTH_TOKEN // Add the token to the body
    };
    return this.http.post<{ status: string; message: string }>(
      `${this.BASE_URL}/resend-otp`,
      requestBody
    );
  }

  login(userLoginInfo: Login): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(
      `${this.BASE_URL}/login`,
      userLoginInfo
    );
  }


  createTransactionPin(
    otp: CreateTransactionPin
  ): Observable<CreateTransactionPinResponse> {
    const AUTH_TOKEN = localStorage.getItem('signupToken');

    // Merge the token into the body along with the otp
    const requestBody = {
      ...otp, // Spread the existing otp object
      token: AUTH_TOKEN // Add the token to the body
    };
    return this.http.post<CreateTransactionPinResponse>(
      `${this.BASE_URL}/create-pin`,
      requestBody
    );
  }

  forgotPassword(
    userEmailInfo: ForgotPassword
  ): Observable<ForgotPasswordResponse> {
    const token = localStorage.getItem('token');
    return this.http.post<ForgotPasswordResponse>(
      `${this.BASE_URL}/forget-password`,
      userEmailInfo
    );
  }

  resetPassword(
    resetPasswordInfo: ResetPassword
  ): Observable<ResetPasswordResponse> {
    return this.http.post<ResetPasswordResponse>(
      `${this.BASE_URL}/reset-password`,
      resetPasswordInfo
    );
  }

  updatePassword(updatePasswordInfo: UpdatePassword) {
    return this.http.post(this.BASE_URL, updatePasswordInfo);
  }

  clearAccountDetails(): void {
    localStorage.removeItem(LOCAL_STORAGE_ACTIVE_ACCOUNT_ID);
    localStorage.removeItem(LOCAL_STORAGE_PROFILE_ID);
  }

  clearToken(): void {
    sessionStorage.removeItem(TOKEN);
  }

  logout(): void {
    this.clearToken();
    this.clearAccountDetails();
  }
}
